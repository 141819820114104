// export const messageHubUrl = "https://ddh-api.ddhcloud.ch/messageHub";
// export const apiBaseUrl ="https://ddh-api.ddhcloud.ch/api";
export const messageHubUrl = "https://ddh-api.ddhcloud.de/messageHub";
export const apiBaseUrl ="https://ddh-api.ddhcloud.de/api"

// export const messageHubUrl = "https://localhost:54729/messageHub";
// export const apiBaseUrl = "https://localhost:54729/api";
// export const messageHubUrl = "https://ddh-test-api.ddhcloud.ch/messageHub";
// export const apiBaseUrl ="https://ddh-test-api.ddhcloud.ch/api";

export const countryCode = "de";
export const stripePaymentActivated = "false";
